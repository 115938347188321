<template>
  <div class="max">
    <h1
      class="_100top purple--text text-center"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
    >
      Merchandise
    </h1>
    <div class="d-flex justify-center">
      <div v-if="merchandise" class="pa-3" style="width: 80%">
        <carousel :perPage="3" navigationNextLabel=">" navigationPrevLabel="<">
          <slide v-for="item in merchandise.data" :key="item.id">
            <div class="d-flex justify-center pa-2">
              <v-hover v-slot="{ hover }">
                <v-card height="350px" width="300px">
                  <v-img
                    :src="`${env}/merchant/upload/${item.gambar}`"
                    class="img_merch"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="
                          transition-fast-in-fast-out
                          black
                          v-card--reveal
                          white--text
                          d-flex
                          justify-center
                          align-center
                        "
                        style="height: 100%"
                      >
                        <div class="text-center pa-3">
                          <h4>{{ item.judul }}</h4>
                          <h3>
                            Rp
                            {{
                              item.harga
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            }}
                          </h3>
                          <div class="mt-3 d-flex justify-center">
                            <v-btn
                              depressed
                              dark
                              color="orange"
                              @click="checkLink(item.link)"
                              >Buy Now</v-btn
                            >
                          </div>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-card>
              </v-hover>
            </div>
          </slide>
        </carousel>
        <div class="d-flex justify-center mt-3">
          <v-btn color="blue" dark to="public/merchant">More</v-btn>
        </div>
        <div class="pa-2">
          <PrevLinkMerchant
            v-bind:dialogLinkmerchant="dialogLinkmerchant"
            v-bind:link="link"
            @close="closeDialog"
          />
        </div>
        <div class="d-flex justify-center mt-5" v-if="!merchandise.data.length">
          <div class="mt-5">
            <img src="../../assets/img/404.svg" height="100px" alt="404" />
            <h3>No Data Found!</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PrevLinkMerchant from "../../components/General/Modal/prevLinkMerchant.vue";
export default {
  name: "merchandisePublic",
  components: { PrevLinkMerchant },
  data() {
    return {
      page: 1,
      perPage: 10,
      link: null,
      dialogLinkmerchant: false
    };
  },
  computed: {
    ...mapState({
      merchandise: state => state.merchandise.listMerch,
      env: state => state.API_URL,
      role: state => state.role
    })
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      let data = {
        page: this.page,
        per_page: this.perPage,
        search: this.search
      };
      this.loader = true;
      this.$store.dispatch("merchandise/listMerchandise", data).then(data => {
        console.log(data);
        this.loader = false;
      });
    },
    checkLink(link) {
      this.link = JSON.parse(link);
      this.dialogLinkmerchant = true;
    },
    closeDialog() {
      this.dialogLinkmerchant = false;
    }
  }
};
</script>

<style scoped>
._wave {
  /* position: relative; */
  overflow: initial;
  background: url("../../assets/img/bg-wave-right.svg");
  background-position: center;
  background-size: cover;
}

.max {
  height: max-content;
}

.container_is_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 75%;
  margin-left: 25%;
}
.container_no_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
.child_is_auth {
  margin-top: 100px;
  padding: 10px;
  width: 100%;
}
.child_no_auth {
  margin-top: 100px !important;
  padding: 10px;
  margin: auto;
  width: 80%;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
.img_merch {
  width: 300px;
  height: 350px;
  object-fit: cover;
}
</style>
