<template>
  <v-row justify="start">
    <v-dialog v-model="dialogJoin" persistent max-width="500px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Join Event</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <div></div>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nama Lengkap"
                  required
                  dense
                  outlined
                  hide-details="auto"
                  v-model="name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  required
                  hide-details="auto"
                  outlined
                  dense
                  v-model="email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="No Hp"
                  required
                  hide-details="auto"
                  outlined
                  dense
                  v-model="no"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" v-if="!loading" outlined rounded @click="joinEvt"
            >Join</v-btn
          >
          <v-btn color="blue" v-if="loading" outlined rounded>Loading..</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "JoinAgenda",
  props: ["dialogJoin", "dataEvt"],

  computed: {
    ...mapState({
      province: state => state.area.province
    })
  },
  data() {
    return {
      name: "",
      no: "",
      email: "",
      loading: false
    };
  },
  mounted() {},
  methods: {
    joinEvt() {
      let body = {
        nama_lengkap: this.name,
        email: this.email,
        no_hp: this.no
      };
      let data = {
        id: this.dataEvt.id,
        body: body
      };
      this.loading = true;
      this.$store
        .dispatch("agenda/joinEvtPublic", data)
        .then(data => {
          this.loading = false;
          if (data.status == "success") {
            console.log(data);
            this.name = "";
            this.email = "";
            this.no = "";
            this.$emit("close");
            if (this.dataEvt.tipe_agenda == "berbayar") {
              this.$emit("showPay", data.data.id);
            }
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
          } else {
            console.log(data);
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    }
  }
};
</script>

<style></style>
