<template>
  <!-- INI YANG ADA MORE NYA, YANG DI LANDING PAGE -->
  <div>
    <div class="d-flex flex-wrap justify-center pa-4" v-if="ebook">
      <v-card
        v-for="(item, q) in ebook.data"
        :key="q"
        :to="`public/ebook/${item.id}/view`"
        class="radius-card artcle_card__ ma-2 pointer__"
        width="250px"
        height="280px"
        content="Klik untuk melihat e-book"
        v-tippy="{
          arrow: true,
          arrowType: 'round',
          animation: 'fade',
          theme: 'light',
          maxWidth: 200
        }"
      >
        <div class="temp_img__">
          <img
            :src="`${env}/ebook/upload/${item.user_id}/image/${item.image}`"
            class="img-fit scale"
            height="150px"
            width="100%"
            alt="cover"
          />
        </div>
        <div class="px-2">
          <h4 v-snip="2">{{ item.judul }}</h4>
          <span v-snip="3" class="_isi mt-2" v-html="item.synopsis"> </span>
        </div>
      </v-card>
      <div v-if="!ebook.data.length" class="d-flex justify-center">
        <section>
          <img src="../../assets/img/404.svg" height="200px" alt="404" />
          <h3 class="text-center">No Data Found!</h3>
        </section>
      </div>
    </div>
    <footer class="d-flex justify-center ma-2" v-if="ebook">
      <v-btn color="blue" dark to="/public/ebook">More</v-btn>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "publicEbook",
  computed: {
    ...mapState({
      ebook: state => state.library.public_ebook_admin,
      env: state => state.API_URL
    })
  },
  mounted() {
    this.$store.dispatch("library/publicEbookAdmin", {
      page: 1,
      limit: 4,
      find: ""
    });
  }
};
</script>

<style scoped>
._isi {
  line-height: 1.2;
}
.artcle_card__ {
  overflow: hidden;
}
.temp_img__ {
  overflow: hidden;
  min-height: 150px;
}
.scale {
  transition: 0.5s;
}
.scale:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.action__ {
  margin-top: -20px;
}
.pointer__ {
  cursor: pointer;
}
</style>
