<template>
  <div class=" max pa-2">
    <h1
      class="_100top purple--text text-center"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
    >
      Service
    </h1>
    <div class="d-flex flex-wrap justify-center mb-5">
      <router-link
        to="/public/cari-psikolog"
        class="text-decoration"
        data-aos="fade-right"
      >
        <section
          class="card d-flex align-center flex-column justify-center blue"
        >
          <img src="../../assets/img/Icon01.svg" alt="" height="80px" />
          <h3 class="white--text mt-2">Carikan Psikolog</h3>
        </section>
      </router-link>
      <router-link
        to="/public/konseling"
        class="text-decoration"
        data-aos="fade-down"
      >
        <section
          class="card d-flex align-center flex-column justify-center blue"
        >
          <img src="../../assets/img/Icon02.svg" alt="" height="80px" />
          <h3 class="white--text mt-2">Konseling</h3>
        </section>
      </router-link>
      <router-link
        to="/public/cari-tahu"
        class="text-decoration"
        data-aos="fade-left"
      >
        <section
          class="card d-flex align-center flex-column justify-center blue"
        >
          <img src="../../assets/img/Icon03.svg" alt="" height="80px" />
          <h3 class="white--text mt-2">Cari Tahu</h3>
        </section>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "mainService",
  data() {
    return {
      dialogFeedback: false
    };
  },
  computed: {
    ...mapState({
      survey: state => state.survey.public_survey,
      last: state => state.admin.last_page,
      env: state => state.API_URL,
      role: state => state.role
    })
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    fetchItem() {
      this.$store.dispatch("survey/publicSurvey");
    },
    closeDialog() {
      this.dialogFeedback = false;
    }
  }
};
</script>

<style scoped>
._wave {
  /* position: relative; */
  overflow: initial;
  background: url("../../assets/img/bg-wave-right.svg");
  background-position: center;
  background-size: cover;
}

.max {
  height: max-content;
}

.card {
  width: 300px;
  height: 150px;
  border-radius: 5px;
  transition: 0.3s;
  margin: 10px 20px;
}

.card:hover {
  box-shadow: 0px 0px 16px 2px rgba(19, 188, 250, 0.31);
  cursor: pointer;
  transition: 0.3s;
  transform: scale(1.1);
}
.__carousel_item {
  width: 100%;
  border-radius: 10px !important;
}
</style>
