<template>
  <div class="_full-w">
    <Nav />
    <HeroCover
      :cover="require('../../assets/img/hero_cover.png')"
      title="Paguyuban Alumni Magister Psikologi UGM"
    />
    <div class="_wave1">
      <div class="temp-map">
        <MapPshycolog class="_100vh" />
      </div>
      <MainService id="service" />
    </div>
    <div class="_wave2">
      <Library id="library" />
      <FeedbackAndSurvey />
      <AgendaPublic />
      <MerchantPublic />
    </div>
    <Footer />
  </div>
</template>

<script>
import HeroCover from "../../components/_base/heroCover";
import MapPshycolog from "../../views/General/mapPshycolog";
import MainService from "../../views/General/MainService";
import Footer from "../General/Footer.vue";
import Library from "../General/Library.vue";
import Nav from "../../components/General/Nav.vue";
import FeedbackAndSurvey from "../../views/General/feedbackAndSurvey.vue";
import AgendaPublic from "../General/AgendaPublic.vue";
import MerchantPublic from "../General/merchantPublic.vue";
export default {
  name: "publicMainPage",
  components: {
    HeroCover,
    MapPshycolog,
    MainService,
    Footer,
    Library,
    FeedbackAndSurvey,
    Nav,
    AgendaPublic,
    MerchantPublic,
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        this.scrollTo(this.$route.hash);
      }
    });
  },
  methods: {
    scrollTo(hash) {
      let elmnt = hash.substring(1);
      document.getElementById(`${elmnt}`).scrollIntoView();
    },
  },
};
</script>

<style scoped>
@media (max-width: 576px) {
  .temp-map {
    display: none;
  }
}
@media (max-width: 768px) {
  .temp-map {
    display: none;
  }
}
</style>
