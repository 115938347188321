<template>
  <v-row justify="start">
    <v-dialog v-model="dialogView" scrollable persistent max-width="800px">
      <v-card class="radius-card" v-if="agenda">
        <v-toolbar dense flat>
          <span class="headline">Agenda</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2">
          <v-container>
            <v-img
              v-if="agenda.data[0].poster"
              :src="`${env}/agenda/${agenda.data[0].poster}`"
              class="img-fit"
            ></v-img>
            <v-col>
              <v-row>
                <v-col cols="12" md="5">Judul</v-col>
                <v-col cols="12" md="7">{{ agenda.data[0].judul }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Deskripsi</v-col>
                <v-col cols="12" md="7">{{ agenda.data[0].deskripsi }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Lokasi</v-col>
                <v-col cols="12" md="7">{{ agenda.data[0].lokasi }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Kategori</v-col>
                <v-col cols="12" md="7">{{
                  agenda.data[0].nama_kategori
                }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">
                  <p>Waktu</p>
                </v-col>

                <v-col cols="12" md="7">
                  <v-row>
                    <v-col cols="12" md="6">
                      <p class="ma-0">waktu mulai</p>
                      <p>
                        {{
                          $date(agenda.data[0].start_date).format("DD-MM-YYYY")
                        }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="ma-0">waktu selesai</p>
                      <p>
                        {{
                          $date(agenda.data[0].end_date).format("DD-MM-YYYY")
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Fasilitas</v-col>
                <v-col cols="12" md="7">{{ agenda.data[0].fasilitas }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Pemateri</v-col>
                <v-col cols="12" md="7">{{ agenda.data[0].pemateri }}</v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "viewAgenda",

  props: ["dialogView", "data"],
  computed: {
    ...mapState({
      env: state => state.API_URL,
      agenda: state => state.agenda.agendaDetail
    })
  },
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.loading = true;
      this.$store
        .dispatch("agenda/publicAgendaView", this.data.id)
        .then(data => {
          console.log(data);
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.temp-cover {
  width: 100%;
  height: 200px;
  border: 1px #000 dotted;
}
</style>
