<template>
  <div>
    <div class="__carousel_item pa-3 deep-purple darken-4">
      <v-row>
        <v-col cols="12" md="3">
          <img
            src="../../assets/img/surveysavag.svg"
            height="200px"
            alt="survey"
            class="mx-5"
          />
        </v-col>
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12" md="6">
              <v-card height="200px" class="survey__ pa-2">
                <h1 class="white--text">Bantu Kami mengisi Survey</h1>
                <div class="d-flex justify-center mt-2">
                  <v-btn color="blue" to="public/survey/list" dark
                    >Lihat Semua Survey</v-btn
                  >
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card height="200px" class="feed__ pa-2">
                <h1 class="white--text">Beri masukan untuk website kami</h1>
                <div class="d-flex justify-center mt-2">
                  <v-btn color="blue" dark @click="dialogFeedback = true"
                    >Isi Umpan Balik</v-btn
                  >
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="pa-2">
      <AddFeedback
        v-bind:dialogFeedback="dialogFeedback"
        @close="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import AddFeedback from "../../components/General/Modal/addFeedback.vue";
export default {
  name: "feedbackand",
  components: { AddFeedback },
  data() {
    return {
      dialogFeedback: false
    };
  },
  methods: {
    closeDialog() {
      this.dialogFeedback = false;
    }
  }
};
</script>

<style scoped>
.survey__ {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/img/survey.jpg");
  background-position: center;
  background-size: cover;
}
.feed__ {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/img/feed.jpg");
  background-position: center;
  background-size: cover;
}
</style>
