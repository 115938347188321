<template>
  <div class="max">
    <h1
      class="_100top purple--text text-center"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
    >
      Event
    </h1>
    <div v-if="agenda" class="pa-3">
      <v-row>
        <v-col
          cols="12"
          md="3"
          lg="3"
          v-for="item in agenda.data"
          :key="item.id"
        >
          <div class="d-flex justify-end">
            <v-chip
              class="label-harga"
              color="green"
              dark
              label
              text-color="white"
            >
              <v-icon left> mdi-label </v-icon>
              <p class="ma-0" v-if="item.tipe_agenda == 'gratis'">Gratis</p>
              <p class="ma-0" v-if="item.tipe_agenda == 'berbayar'">
                Rp. {{ item.biaya }}
              </p>
            </v-chip>
          </div>
          <v-card
            class="radius-card artcle_card__ ma-2 pa-3 d-flex align-end"
            height="400px"
            :id="`evt-${item.id}`"
          >
            <div class="pa-3 temp-info">
              <h4 v-snip="1">{{ item.judul }}</h4>
              <p class="ma-0 grey--text">
                by <b>{{ item.pemateri }}</b>
              </p>
              <div class="d-flex">
                <v-card
                  class="px-3 py-2 d-flex align-center"
                  style="width: max-content"
                  color="#EEEEEE"
                  flat
                >
                  <v-icon class="mr-2 mt-2">mdi-calendar</v-icon>
                  {{ $date(item.start_date).format("DD MMM, YYYY") }}
                </v-card>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text class="px-0 ml-2">
                      <v-icon>mdi-account-multiple</v-icon>
                      {{ item.kuota - item.available.sisa }}/{{ item.kuota }}
                    </v-btn>
                  </template>
                  <span>Kuota</span>
                </v-tooltip>
              </div>

              <v-card outlined class="mt-2 pa-2" height="130px">
                <div class="d-flex justify-center">
                  <v-icon>mdi-map-marker-radius</v-icon>
                </div>
                <p class="ma-0 text-center">{{ item.lokasi }}</p>
              </v-card>
              <div class="pa-2">
                <v-btn
                  class="mr-1"
                  dark
                  color="blue"
                  depressed
                  @click="viewEvt(item)"
                >
                  Lihat</v-btn
                >
                <!-- <v-btn
                  v-if="item.available.status"
                  dark
                  color="orange"
                  depressed
                  @click="joinEvt(item)"
                  >Join</v-btn
                > -->
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- {{ agenda.data }} -->
      <JoinAgendaPublic
        v-bind:dialogJoin="dialogJoin"
        v-bind:dataEvt="dataEvt"
        @close="closeDialog"
        @showPay="uploadStruk"
      />
      <ViewAgendaPublic
        v-if="dataEvt"
        v-bind:dialogView="dialogView"
        v-bind:data="dataEvt"
        @close="closeDialog"
      />
      <PayAgenda
        v-bind:dialogPay="dialogPay"
        v-bind:idParticipant="idParticipant"
        @close="closeDialog"
      />
      <div class="d-flex justify-center mt-5" v-if="!agenda.data.length">
        <div class="mt-5">
          <img src="../../assets/img/404.svg" height="100px" alt="404" />
          <h3>No Data Found!</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ViewAgendaPublic from "../../components/Admin/Modal/viewAgendaPublic.vue";
import JoinAgendaPublic from "../../components/General/Modal/joinAgendaPublic.vue";
import PayAgenda from "../../components/General/Modal/payEvt.vue";
export default {
  name: "agenda",
  components: { JoinAgendaPublic, PayAgenda, ViewAgendaPublic },
  data() {
    return {
      dialogFeedback: false,
      dialogPay: false,
      dialogView: false,
      page: 1,
      perPage: 10,
      dialogJoin: false,
      dataEvt: null,
      idParticipant: null
    };
  },
  computed: {
    ...mapState({
      agenda: state => state.agenda.agenda,
      env: state => state.API_URL,
      role: state => state.role
    })
  },
  mounted() {
    this.fetchAgenda();
  },
  methods: {
    joinEvt(data) {
      this.dialogJoin = true;
      this.dataEvt = data;
    },
    viewEvt(data) {
      this.dialogView = true;
      this.dataEvt = data;
    },
    uploadStruk(data) {
      this.dialogPay = true;
      console.log(data);
      this.idParticipant = data;
    },
    closeDialog() {
      this.dialogView = false;
      this.dialogJoin = false;
      this.dialogPay = false;
      this.dataEvt = null;
    },
    fetchAgenda() {
      let data = {
        page: this.page,
        per_page: this.perPage,
        search: this.search
      };
      this.loader = true;
      this.$store.dispatch("agenda/publicAgenda", data).then(data => {
        console.log(data);
        data.forEach(el => {
          document.getElementById(
            `evt-${el.id}`
          ).style.background = `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url('${this.env}/agenda/${el.poster}')`;
        });
        this.loader = false;
      });
    }
  }
};
</script>

<style scoped>
._wave {
  /* position: relative; */
  overflow: initial;
  background: url("../../assets/img/bg-wave-right.svg");
  background-position: center;
  background-size: cover;
}

.max {
  height: max-content;
}

.container_is_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 75%;
  margin-left: 25%;
}
.container_no_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
.child_is_auth {
  margin-top: 100px;
  padding: 10px;
  width: 100%;
}
.child_no_auth {
  margin-top: 100px !important;
  padding: 10px;
  margin: auto;
  width: 80%;
}
._isi {
  line-height: 1.2;
}
.artcle_card__ {
  overflow: hidden;
}
.temp_img__ {
  overflow: hidden;
  min-height: 150px;
}
.scale {
  transition: 0.5s;
}
.scale:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.action__ {
  margin-top: -20px;
}
.pointer__ {
  cursor: pointer;
}
.temp-info {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 300px;
}
.label-harga {
  position: relative;
  margin-bottom: -30px;
  z-index: 2;
}
</style>
