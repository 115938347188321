<template>
  <v-row justify="start">
    <v-dialog v-model="dialogPay" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline"></span>
          <v-spacer></v-spacer>
          <!-- <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          > -->
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-file-input
                  label="Upload Bukti Pembayaran"
                  v-model="file"
                  outlined
                  dense
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined rounded @click="closeDialog"
            >cancel</v-btn
          >
          <v-btn color="blue" outlined rounded v-if="!loading" @click="pay"
            >Submit</v-btn
          >
          <v-btn color="blue" outlined rounded v-if="loading">Loading</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "PayAgenda",
  props: ["dialogPay", "idParticipant"],

  data() {
    return {
      no_rek: "",
      file: null,
      loading: false
    };
  },
  mounted() {},
  methods: {
    closeDialog() {
      Swal.fire({
        title: "Yakin keluar dari halaman ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak"
      }).then(result => {
        if (result.isConfirmed) {
          this.$emit("close");
        }
      });
    },
    pay() {
      this.loading = true;
      let body = new FormData();
      body.append("agenda_participant_id", this.idParticipant);
      body.append("metode_pembayaran", "transfer");
      body.append("no_rekening", "083812609066");
      body.append("objBuktiPembayaran", this.file);
      this.$store
        .dispatch("agenda/payEvt", body)
        .then(data => {
          this.loading = false;
          if (data.status == "success") {
            this.$emit("close");
            Swal.fire({
              icon: "success",
              title: data.message
            });
          } else {
            console.log(data);
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
          }
        })
        .catch(err => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
          console.log(err);
        });
    }
  }
};
</script>

<style></style>
