<template>
  <div class="_100vh pa-2">
    <h1
      class="mb-2 text-center purple--text _100top"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
    >
      Library
    </h1>
    <div>
      <v-tabs
        v-model="tab"
        class="mb-5 rounded"
        background-color="purple lighten-5"
        color="purple"
        grow
      >
        <v-tab href="#tab-1"><v-icon>mdi-book</v-icon><b>Article</b></v-tab>
        <v-tab href="#tab-2"
          ><v-icon>mdi-book-multiple</v-icon><b>E-book</b></v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tab" style="background: none">
        <v-tab-item value="tab-1">
          <ListArticle />
        </v-tab-item>
        <v-tab-item value="tab-2">
          <ListEbook />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import ListArticle from "../../components/General/listArticle.vue";
import ListEbook from "../../components/General/ListEbook.vue";
export default {
  name: "Library",
  data() {
    return {
      tab: null,
      isActive: false
    };
  },
  components: {
    ListArticle,
    ListEbook
  }
};
</script>

<style scoped></style>
