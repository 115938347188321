<template>
  <v-row justify="start">
    <v-dialog v-model="dialogFeedback" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Beri kami umpan balik</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2">
          <v-container>
            <v-textarea
              label="Tulis umpan balik"
              solo
              counter
              v-model="feedback"
            ></v-textarea>
            <v-file-input
              v-model="file"
              solo
              accept="image/*"
              label="Add Image"
            ></v-file-input>
            <div>
              <h3 class="mb-3">Tipe :</h3>
              <v-btn
                rounded
                class="mr-3"
                :dark="title == 'Saran' ? true : false"
                :color="title == 'Saran' ? 'green' : ''"
                @click="title = 'Saran'"
                >Saran</v-btn
              >
              <v-btn
                rounded
                class="mr-3"
                :dark="title == 'Bug' ? true : false"
                :color="title == 'Bug' ? 'green' : ''"
                @click="title = 'Bug'"
                >Bug</v-btn
              >
              <v-btn
                rounded
                class="mr-3"
                :dark="title == 'Lainnya' ? true : false"
                :color="title == 'Lainnya' ? 'green' : ''"
                @click="title = 'Lainnya'"
                >Lainnya</v-btn
              >
            </div>
          </v-container>
        </v-card-text>
        <notifications group="validate" class="mt-2" position="top center" />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            outlined
            rounded
            @click="addFeedback"
            v-if="!loading"
            >Kirim</v-btn
          >
          <v-btn color="blue" outlined rounded v-if="loading"
            >Loading ...</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
// import { mapState } from "vuex";

export default {
  name: "feedback",
  props: ["dialogFeedback"],

  data() {
    return {
      loading: false,
      feedback: "",
      title: "Saran",
      file: null
    };
  },

  mounted() {},

  methods: {
    addFeedback() {
      this.loading = true;
      // let data = {
      //   isi: this.feedback,
      //   objImage: this.file,
      //   judul: this.title,
      // };
      let data = new FormData();
      data.append("isi", this.feedback);
      data.append("objFile", this.file);
      data.append("judul", this.title);
      this.$store
        .dispatch("giveFeedback", data)
        .then(data => {
          this.$emit("close");
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        })
        .catch(err => {
          for (let i in err) {
            this.alert("error", `${err[i][0]}`);
          }
          this.loading = false;
        });
    },

    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.img-temp__ {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px black dotted;
}
.bg {
  background: #ffff;
}
</style>
